// import libraries
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
// import utils
import { getData, postData, putDataID } from '../utils/api';
import { API_ROUTES } from '../utils/constants';
import { CURRENCIES } from '../utils/currencies';
// import css
import '../assets/styles/buttonModalAdmin.scss';
import 'flag-icons/css/flag-icons.min.css';

/*
ButtonModalAdmin component
@params :
  - firstParams: {type} - description

Component description
*/

const initialState = {
  id_network: null,
  value: '',
  name: '',
  town: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  zip_code: '',
  country: '',
  locale: null,
  website: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.value };
    case 'UPDATE':
      return { ...state, [action.field]: action.value };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export default function ButtonModalAdmin({
  iconButton,
  nameButton,
  variantButton,
  type,
  data,
  size,
  className,
  networks,
  tooltip,
}) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (data && show) {
      if (type === 'agency') {
        const fetchData = async () => {
          const response = await getData(API_ROUTES.USERS.GET.AGENCIES);
          dispatch({
            type: 'SET_STATE',
            value: response.data.filter(
              (item) => item.id === data.id_agency,
            )[0],
          });
        };
        fetchData();
        dispatch({ type: 'UPDATE', field: 'value', value: data.agency });
      } else {
        dispatch({ type: 'UPDATE', field: 'value', value: data.network });
      }
    }
    // eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    dispatch({ type: 'UPDATE', field: 'phone', value: phone });
  }, [phone]);

  const handleChange = (field) => (e) => {
    if (field === 'value') {
      dispatch({ type: 'UPDATE', field: 'town', value: e.target.value });
    }
    dispatch({ type: 'UPDATE', field, value: e.target.value });
  };

  const handleSelect = (eventKey) => {
    if (type === 'agency') {
      dispatch({ type: 'UPDATE', field: 'id_network', value: eventKey });
    } else {
      dispatch({ type: 'UPDATE', field: 'value', value: eventKey });
    }
  };

  const handleSelectLocale = (eventKey) => {
    dispatch({ type: 'UPDATE', field: 'locale', value: eventKey });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let response;
    if (data && type === 'agency') {
      response = await putDataID(
        API_ROUTES.USERS.PUT.AGENCY,
        state,
        data.id_agency,
      );
    } else {
      if (type === 'agency') {
        response = await postData(API_ROUTES.USERS.POST.AGENCY, state);
      } else {
        response = await postData(API_ROUTES.USERS.POST.NETWORK, state);
      }
    }
    if (response.status === 201 || response.status === 200) {
      handleClose();
      dispatch({ type: 'RESET' });
    }
    setLoading(false);
  };

  const handleReset = () => {
    handleClose();
    dispatch({ type: 'RESET' });
  };

  return (
    <React.Fragment>
      <Button
        variant={variantButton}
        size={size}
        onClick={handleShow}
        className={className}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={tooltip}
      >
        {iconButton ? (
          <React.Fragment>
            {iconButton}
            <span>{nameButton}</span>
          </React.Fragment>
        ) : (
          nameButton
        )}
      </Button>

      <Modal show={show} onHide={handleReset}>
        <Modal.Header closeButton>
          <Modal.Title>
            {data
              ? `${t('components.buttonModalAdmin.update')} ${
                  type === 'agency'
                    ? `agency: ${data.agency}`
                    : `network: ${data.network}`
                }`
              : `${t('components.buttonModalAdmin.create')} ${type}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="form__adding__entity"
            onSubmit={(e) => handleSubmit(e)}
            onReset={handleReset}
            className="modal__body__form"
          >
            <Form.Group className="modal__body__form__group">
              {type === 'agency' && (
                <Dropdown
                  onSelect={(eventKey, e) => handleSelect(eventKey)}
                  className="modal__body__form__group__dropdown"
                >
                  <Dropdown.Toggle id="dropdown-agency">
                    {state.id_network
                      ? networks.filter(
                          (item) => item.id === Number(state.id_network),
                        )[0].value
                      : `${t('components.buttonModalAdmin.selectNetwork')}`}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="modal__body__form__group__dropdown__menu">
                    {networks.map((item) => {
                      return (
                        <Dropdown.Item key={item.id} eventKey={item.id}>
                          {item.value}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Form.Control
                type="text"
                placeholder={
                  type === 'agency'
                    ? `${t('components.buttonModalAdmin.agency')} city`
                    : `${t('components.buttonModalAdmin.network')}`
                }
                value={state.value}
                onChange={handleChange('value')}
              />
              <Form.Control
                type="text"
                placeholder="Name"
                value={state.name}
                onChange={handleChange('name')}
              />
            </Form.Group>
            {type === 'agency' && (
              <React.Fragment>
                <Form.Group className="modal__body__form__group">
                  <Form.Control
                    type="email"
                    placeholder={t('components.buttonModalAdmin.email')}
                    value={state.email}
                    onChange={handleChange('email')}
                  />
                  <PhoneInput
                    placeholder={t('components.buttonModalAdmin.phone')}
                    name="phone"
                    defaultCountry="FR"
                    value={state?.phone}
                    onChange={(value) => setPhone(value || '')}
                    inputComponent={Form.Control}
                    className="w-75"
                  />
                </Form.Group>
                <Form.Group className="modal__body__form__group">
                  <Form.Control
                    placeholder={t('components.buttonModalAdmin.address1')}
                    name="address1"
                    value={state.address1}
                    onChange={handleChange('address1')}
                  />
                </Form.Group>
                <Form.Group className="modal__body__form__group">
                  <Form.Control
                    placeholder={t('components.buttonModalAdmin.address2')}
                    name="address2"
                    value={state.address2}
                    onChange={handleChange('address2')}
                  />
                </Form.Group>
                <Form.Group className="modal__body__form__group">
                  <Form.Control
                    placeholder={t('components.buttonModalAdmin.zipCode')}
                    name="zip_code"
                    value={state.zip_code}
                    onChange={handleChange('zip_code')}
                  />
                  <Form.Control
                    placeholder={t('components.buttonModalAdmin.country')}
                    name="country"
                    value={state.country}
                    onChange={handleChange('country')}
                  />
                </Form.Group>
                <Form.Group className="modal__body__form__group">
                  <Form.Control
                    placeholder={t('components.buttonModalAdmin.website')}
                    name="website"
                    value={state.website}
                    onChange={handleChange('website')}
                  />
                </Form.Group>
                <Form.Group className="modal__body__form__group">
                  <Dropdown
                    onSelect={(eventKey, e) => handleSelectLocale(eventKey)}
                    className="modal__body__form__group__dropdownCurrency"
                  >
                    <Dropdown.Toggle id="dropdown-currency">
                      {state.locale
                        ? `${t('components.buttonModalAdmin.currency')} ${
                            CURRENCIES.filter(
                              (item) => item.locale === state.locale,
                            )[0].country
                          }`
                        : `${t('components.buttonModalAdmin.selectCurrency')}`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="modal__body__form__group__dropdownCurrency__menuCurrency">
                      {CURRENCIES.map((item, index) => {
                        // Extraire le code pays à partir du locale, par exemple 'fr-FR' => 'fr'
                        const countryCode = item.locale
                          .split('-')[1]
                          .toLowerCase();

                        return (
                          <Dropdown.Item key={index} eventKey={item.locale}>
                            <span
                              className={`fi fi-${countryCode} modal__body__form__group__dropdownCurrency__menuCurrency__flag`}
                            ></span>
                            {item.code} | {item.country} - {item.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </React.Fragment>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleReset} disabled={loading}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              `${t('components.buttonModalAdmin.cancel')}`
            )}
          </Button>
          <Button
            variant="success"
            type="submit"
            form="form__adding__entity"
            disabled={
              loading || type === 'agency'
                ? !state.value ||
                  !state.town ||
                  !state.id_network ||
                  !state.email ||
                  !isValidPhoneNumber(state.phone) ||
                  !state.address1 ||
                  !state.zip_code ||
                  !state.country ||
                  !state.locale
                : !state.value
            }
            onClick={handleSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              `${t('components.buttonModalAdmin.validate')}`
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
