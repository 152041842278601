// import libraries
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

/*
Button Modal Component:
Displays a button showing a modal answering a yes-no question.

@params {icon} iconButton - The icon of button.
@params {string} nameButton - The text of button.
@params {string} variantButton - Variant of bootstrap button.
@params {string} modalTitle - Title of the modal.
@params {string} modalBody - Text of the modal.
@params {icon} iconConfirm - The icon of the valide button.
@params {string} nameConfirm - The text of the valide button.
@params {string} variantConfirm - Variant of valide button.
@params {function} handleConfirm - Function call on click valide button.
@params {icon} iconCancel - The icon of the cancel button.
@params {string} nameCancel - The text of the cancel button.
@params {string} variantCancel - Variant of cancel button.
@params {function} handleCancel - Function call on click cancel button.

*/

export default function ButtonModal({
  iconButton,
  nameButton,
  variantButton,
  size,
  className,
  modalTitle,
  modalBody,
  iconConfirm,
  nameConfirm,
  variantConfirm,
  handleConfirm,
  iconCancel,
  nameCancel,
  variantCancel,
  handleCancel,
  tooltip,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConfirmClose = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        variant={variantButton}
        size={size}
        onClick={handleShow}
        className={className}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={tooltip}
      >
        {iconButton ? (
          <React.Fragment>
            {iconButton}
            <span>{nameButton}</span>
          </React.Fragment>
        ) : (
          nameButton
        )}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
        </Modal.Header>
        {modalBody && <Modal.Body>{modalBody}</Modal.Body>}
        <Modal.Footer>
          <Button
            variant={variantCancel}
            onClick={handleCancel ? handleCancel : handleClose}
          >
            {iconCancel ? `${iconCancel} ${nameCancel}` : nameCancel}
          </Button>
          <Button variant={variantConfirm} onClick={handleConfirmClose}>
            {iconConfirm ? `${iconConfirm} ${nameConfirm}` : nameConfirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
