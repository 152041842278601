// import libraries
import React from 'react';
// import css
import '../assets/styles/dashboardUtils.scss';

export default function DashboardCardNumber({
  icon,
  number,
  title,
  colorIcon,
  backgroundIcon,
}) {
  return (
    <div className="dashboard__cardNumber">
      <div
        className="dashboard__cardNumber__icon"
        style={{ color: colorIcon, backgroundColor: backgroundIcon }}
      >
        {icon}
      </div>
      <div className="dashboard__cardNumber__content">
        <span className="dashboard__cardNumber__content__number">{number}</span>
        <span className="dashboard__cardNumber__content__title">{title}</span>
      </div>
    </div>
  );
}
