// import libraries
import React, { useEffect, useState, useReducer } from 'react';
import {
  Row,
  Form,
  Col,
  InputGroup,
  Dropdown,
  Spinner,
  FloatingLabel,
  Button,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from 'react-icons/io';
import { useTranslation } from 'react-i18next';
// import utils
import { CURRENCIES } from '../../utils/currencies';
import { API_ROUTES } from '../../utils/constants';
import { getData, postCreateBoat, postDataID } from '../../utils/api';
// import css
import '../../assets/styles/createBoat.scss';

function validateFields(state) {
  return {
    brand: state.brand.trim() === '',
    model: state.model.trim() === '',
    year: state.year.trim().length < 4,
    typology: state.id_typology === '0',
  };
}

// Définition du state initial du formulaire
const initialFormState = {
  brand: '',
  model: '',
  name: '',
  id_status: 3,
  year: '',
  price: 0,
  act_length: null,
  adm_length: null,
  act_width: null,
  adm_width: null,
  draft: null,
  air_draft: null,
  headroom: null,
  weight: null,
  berth_place: false,
  berth_place_description: '',
  town: '',
  country: '',
  serial_number: '',
  registration: '',
  id_typology: '0',
  id_flag: 1,
  id_keel: 0,
  locale: 'fr-FR',
  ttc: true,
};

// Création du reducer pour gérer le state du formulaire
function formReducer(state, action) {
  switch (action.type) {
    case 'SET_FIELD': {
      // Mise à jour d'un champ texte/générique
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case 'SET_NUMBER': {
      // Mise à jour d'un champ numérique (en entier ou multiplié par 100)
      const parsedValue = parseFloat(action.value) || 0;
      return {
        ...state,
        [action.field]: action.isCentimeters ? parsedValue * 100 : parsedValue,
      };
    }
    case 'TOGGLE_BOOL': {
      // Inversion (checkbox)
      return {
        ...state,
        [action.field]: !state[action.field],
      };
    }
    case 'RESET_FORM': {
      // Remise à zéro du formulaire
      return initialFormState;
    }
    case 'POPULATE_FORM': {
      // Pour charger un state pré-rempli si besoin
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default function CreateBoat() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // useState pour gérer le chargement
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  // useState pour stocker les données des selects
  const [status, setStatus] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [flags, setFlags] = useState([]);
  const [keels, setKeels] = useState([]);

  // useReducer pour le formulaire
  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  // Validation des champs
  const errors = validateFields(formState);

  // Au montage, on va chercher les infos pour les selects
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseStatus = await getData(API_ROUTES.BOATS.GET.STATUS);
        setStatus(responseStatus.data);

        const responseTypologies = await getData(
          API_ROUTES.BOATS.GET.TYPOLOGIES,
        );
        setTypologies(responseTypologies.data);

        const responseFlags = await getData(API_ROUTES.BOATS.GET.FLAGS);
        setFlags(responseFlags.data);

        const responseKeels = await getData(API_ROUTES.BOATS.GET.KEELS);
        setKeels(responseKeels.data);

        // Remplir les champs par défaut
        const storedIDs = JSON.parse(localStorage.getItem('ids'));
        dispatch({
          type: 'POPULATE_FORM',
          payload: {
            id_status: 3,
            id_typology: '0',
            id_flag: 1,
            locale: storedIDs?.locale || 'fr-FR',
            ttc: true,
          },
        });
      } catch (error) {
        if (error?.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login'); // rediriger vers login
        }
        console.log('createboat - fetchData error : ', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [navigate, t]);

  // Handler du formulaire - création du bateau
  const handleSave = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    // Appel API
    const userInfos = JSON.parse(localStorage.getItem('ids'));
    const response = await postCreateBoat(
      formState, // On envoie directement le state
      API_ROUTES.BOATS.POST.DETAILS,
      userInfos?.user,
    );
    if (response?.data?.id) {
      // On rajoute un event = "Boat creation"
      const data = {
        id_events: 1,
        date: new Date(),
        description: 'Boat creation',
      };
      await postDataID(API_ROUTES.BOATS.POST.EVENTS, data, response.data.id);
      setIsCreating(false);
      navigate(`/boats/${response?.data.id}`); // navigation vers la page du nouveau bateau
    }
  };

  // Réinitialisation du formulaire
  const handleCancel = () => {
    dispatch({ type: 'RESET_FORM' });
  };

  // Handlers pour chaque type de champ
  // Champ générique (texte, select…)
  const handleChangeField = (field) => (e) => {
    dispatch({ type: 'SET_FIELD', field, value: e.target.value });
  };

  // Champ numérique avec ou sans multiplication par 100
  const handleNumberField =
    (field, isCentimeters = false) =>
    (e) => {
      dispatch({
        type: 'SET_NUMBER',
        field,
        value: e.target.value,
        isCentimeters,
      });
    };

  // Champ checkbox
  const handleToggle = (field) => (e) => {
    dispatch({ type: 'TOGGLE_BOOL', field });
  };

  // Pour la dropdown de currency
  const handleSelectLocale = (eventKey) => {
    dispatch({ type: 'SET_FIELD', field: 'locale', value: eventKey });
  };

  return (
    <div className="createBoat">
      <h1 className="mb-3 mt-5">{t('components.createBoat')}</h1>
      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <Form
          id="form"
          className="createBoat__form"
          onSubmit={handleSave}
          onReset={handleCancel}
          noValidate
        >
          {/* 1ère ligne - brand, model, name, id_status */}
          <Row className="mb-4">
            <Form.Group as={Col} md={2}>
              <FloatingLabel
                controlId="boatBrand"
                label={t('pages.boat.edit.details.brand')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.brand')}
                  value={formState.brand}
                  onChange={handleChangeField('brand')}
                  isInvalid={errors.brand}
                  isValid={!errors.brand}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col}>
              <FloatingLabel
                controlId="boatModel"
                label={t('pages.boat.edit.details.model')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.model')}
                  value={formState.model}
                  onChange={handleChangeField('model')}
                  isInvalid={errors.model}
                  isValid={!errors.model}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={2}>
              <FloatingLabel
                controlId="boatName"
                label={t('pages.boat.edit.details.name')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.name')}
                  value={formState.name}
                  onChange={handleChangeField('name')}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md={2}>
              <FloatingLabel controlId="boatStatus" label={t('label.status')}>
                <Form.Select
                  value={formState.id_status}
                  onChange={handleChangeField('id_status')}
                >
                  {status.map((element, index) => (
                    <option key={index} value={element.id}>
                      {t(element.tag)}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Row>

          <hr />

          {/* 2ème ligne - year, typology, flag */}
          <Row className="mb-3">
            <Form.Group as={Col}>
              <FloatingLabel
                controlId="boatYear"
                label={t('pages.boat.edit.details.year')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.year')}
                  maxLength={4}
                  value={formState.year}
                  onChange={(e) => {
                    const numeric = e.target.value.replace(/[^0-9]/g, '');
                    dispatch({
                      type: 'SET_FIELD',
                      field: 'year',
                      value: numeric,
                    });
                  }}
                  isInvalid={errors.year}
                  isValid={!errors.year}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col}>
              <FloatingLabel
                controlId="boatTypology"
                label={t('label.typology')}
              >
                <Form.Select
                  value={formState.id_typology}
                  onChange={handleChangeField('id_typology')}
                  isInvalid={errors.typology}
                  isValid={!errors.typology}
                >
                  <option value={0}>Select a typology</option>
                  {typologies.map((element, index) => (
                    <option key={index} value={element.id}>
                      {t(element.tag)}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel controlId="boatFlag" label="Flag">
                <Form.Select
                  value={formState.id_flag}
                  onChange={handleChangeField('id_flag')}
                >
                  {flags.map((element, index) => (
                    <option key={index} value={element.id}>
                      {t(element.tag)}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Row>

          {/* 3ème ligne - draft, act_length, adm_length */}
          <Row className="mb-3">
            <Form.Group as={Col}>
              <InputGroup>
                <FloatingLabel
                  controlId="boatActLength"
                  label={t('pages.boat.edit.details.length')}
                  className="flex-grow-1"
                >
                  <Form.Control
                    placeholder={t('pages.boat.edit.details.length')}
                    onChange={handleNumberField('act_length', true)}
                  />
                </FloatingLabel>
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <InputGroup>
                <FloatingLabel
                  controlId="boatActWidth"
                  label={t('pages.boat.edit.details.width')}
                  className="flex-grow-1"
                >
                  <Form.Control
                    placeholder={t('pages.boat.edit.details.width')}
                    onChange={handleNumberField('act_width', true)}
                  />
                </FloatingLabel>
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <InputGroup>
                <FloatingLabel
                  controlId="boatDraft"
                  label={t('pages.boat.edit.details.draft')}
                  className="flex-grow-1"
                >
                  <Form.Control
                    placeholder={t('pages.boat.edit.details.draft')}
                    onChange={handleNumberField('draft', true)}
                  />
                </FloatingLabel>
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Row>

          {/* 4ème ligne - airDraft, width, admWidth */}
          <Row className="mb-3">
            <Form.Group as={Col}>
              <InputGroup>
                <FloatingLabel
                  controlId="boatAdmLength"
                  label={t('pages.boat.edit.details.admLength')}
                  className="flex-grow-1"
                >
                  <Form.Control
                    placeholder={t('pages.boat.edit.details.admLength')}
                    onChange={handleNumberField('adm_length', true)}
                  />
                </FloatingLabel>
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <InputGroup>
                <FloatingLabel
                  controlId="boatAdmWidth"
                  label={t('pages.boat.edit.details.admWidth')}
                  className="flex-grow-1"
                >
                  <Form.Control
                    placeholder={t('pages.boat.edit.details.admWidth')}
                    onChange={handleNumberField('adm_width', true)}
                  />
                </FloatingLabel>
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <InputGroup>
                <FloatingLabel
                  controlId="boatAirDraft"
                  label={t('pages.boat.edit.details.airDraft')}
                  className="flex-grow-1"
                >
                  <Form.Control
                    placeholder={t('pages.boat.edit.details.airDraft')}
                    onChange={handleNumberField('air_draft', true)}
                  />
                </FloatingLabel>
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Row>

          {/* 5ème ligne - headroom, weight, taxNav */}
          <Row className="mb-3">
            <Form.Group as={Col} md={4}>
              <InputGroup>
                <FloatingLabel
                  controlId="boatHeadroom"
                  label={t('pages.boat.edit.details.headroom')}
                  className="flex-grow-1"
                >
                  <Form.Control
                    placeholder={t('pages.boat.edit.details.headroom')}
                    onChange={handleNumberField('headroom', true)}
                  />
                </FloatingLabel>
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md={4}>
              <InputGroup>
                <FloatingLabel
                  controlId="boatWeight"
                  label={t('pages.boat.edit.details.weight')}
                  className="flex-grow-1"
                >
                  <Form.Control
                    placeholder={t('pages.boat.edit.details.weight')}
                    onChange={handleNumberField('weight', false)}
                  />
                </FloatingLabel>
                <InputGroup.Text>kg</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <FloatingLabel controlId="boatKeel" label="Keel">
                <Form.Select
                  value={formState.id_keel}
                  onChange={handleChangeField('id_keel')}
                >
                  {keels.map((element, index) => (
                    <option key={index} value={element.id}>
                      {t(element.tag)}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Row>

          {/* 7ème ligne - keel, berth_place & berth_place_description */}
          <Row className="mb-3">
            <Form.Group as={Col} className="d-flex align-items-center">
              <Form.Check
                id="boatBerthPlace"
                type="checkbox"
                label={t('pages.boat.edit.details.berthPlace')}
                checked={formState.berth_place}
                onChange={handleToggle('berth_place')}
              />
            </Form.Group>
            <Form.Group as={Col} md={10}>
              <FloatingLabel
                controlId="boatBerthDesc"
                label={t('label.description')}
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  disabled={!formState.berth_place}
                  value={formState.berth_place_description}
                  onChange={handleChangeField('berth_place_description')}
                  placeholder={t('label.description')}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>

          {/* 8ème ligne - town, country */}
          <Row className="mb-3">
            <Form.Group as={Col} md={6}>
              <FloatingLabel
                controlId="boatTown"
                label={t('pages.boat.edit.details.town')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.town')}
                  value={formState.town}
                  onChange={handleChangeField('town')}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <FloatingLabel
                controlId="boatCountry"
                label={t('pages.boat.edit.details.country')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.country')}
                  value={formState.country}
                  onChange={handleChangeField('country')}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>

          {/* 9ème ligne - serial_number, registration */}
          <Row>
            <Form.Group as={Col}>
              <FloatingLabel
                controlId="boatSerialNumber"
                label={t('pages.boat.edit.details.serialNumber')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.serialNumber')}
                  value={formState.serial_number}
                  onChange={handleChangeField('serial_number')}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel
                controlId="boatRegistration"
                label={t('pages.boat.edit.details.registration')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.registration')}
                  value={formState.registration}
                  onChange={handleChangeField('registration')}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>

          <hr />

          {/* 10ème ligne - price, locale, ttc */}
          <Row>
            <Form.Group as={Col}>
              <FloatingLabel
                controlId="boatPrice"
                label={t('pages.boat.edit.details.price')}
              >
                <Form.Control
                  placeholder={t('pages.boat.edit.details.price')}
                  value={formState.price}
                  onChange={handleChangeField('price')}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} className="d-flex align-items-center">
              <Dropdown className="w-100" onSelect={handleSelectLocale}>
                <Dropdown.Toggle id="dropdown-currency" className="w-100">
                  {formState.locale && (
                    <span
                      className={`fi fi-${
                        formState.locale.split('-')[1]?.toLowerCase() || 'fr'
                      } me-2`}
                    ></span>
                  )}
                  {formState.locale
                    ? `${
                        CURRENCIES.filter(
                          (item) => item.locale === formState.locale,
                        )[0].code
                      } | ${
                        CURRENCIES.filter(
                          (item) => item.locale === formState.locale,
                        )[0].country
                      } - ${
                        CURRENCIES.filter(
                          (item) => item.locale === formState.locale,
                        )[0].name
                      }`
                    : 'Select currency*'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {CURRENCIES.map((item, index) => {
                    const countryCode = item.locale.split('-')[1].toLowerCase();
                    return (
                      <Dropdown.Item key={index} eventKey={item.locale}>
                        <span className={`fi fi-${countryCode} me-2`}></span>
                        {item.code} | {item.country} - {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>

            <Form.Group as={Col} className="d-flex align-items-center">
              <Form.Check
                id="boatTTC"
                type="checkbox"
                label={t('pages.boat.edit.details.ttc')}
                checked={formState.ttc}
                onChange={handleToggle('ttc')}
              />
            </Form.Group>
          </Row>
        </Form>
      )}

      {/* Boutons de validation et d'annulation */}
      <div className="buttons__container mt-4">
        <Button
          variant="success"
          type="submit"
          form="form"
          disabled={
            isCreating ||
            formState.brand.length === 0 ||
            formState.model.length === 0 ||
            formState.year.length < 4 ||
            formState.id_typology === '0'
          }
          className="flex align-items-center fs-4"
        >
          {isCreating ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <IoMdCheckmarkCircleOutline />
          )}
        </Button>
        <Button
          variant="danger"
          type="reset"
          form="form"
          disabled={
            isCreating ||
            formState.brand.length === 0 ||
            formState.model.length === 0 ||
            formState.year.length < 4 ||
            formState.id_typology === '0'
          }
          className="flex align-items-center fs-4"
        >
          {isCreating ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <IoMdCloseCircleOutline />
          )}
        </Button>
      </div>
    </div>
  );
}
