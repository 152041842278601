// import libraries
import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { getDashboard, getData } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { FaSailboat } from 'react-icons/fa6';
import { TbSpeedboat } from 'react-icons/tb';
// import components
import DashboardCardNumber from '../../components/dashboardCardNumber';
// import css
import '../../assets/styles/dashboard.scss';

/*
Dashboard page
WIP
Display only the first name of the logged-in user. The default page.
*/

function Dashboard() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // data receive of fetch
  const [data, setData] = useState([]);
  // display spinner during fetch
  const [isLoading, setLoading] = useState(false);
  const [isLog, setLog] = useState(false);
  // features
  const featureDashboardBroker = 'dashboard_broker';
  const featureDashboardAgency = 'dashboard_agency';
  const featureDashboardNetwork = 'dashboard_network';
  const featureDashboardAgencyOther = 'dashboard_broker_other_agency';
  const featureDashboardNetworkOther = 'dashboard_broker_other_network';
  const [auth, setAuth] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchAuthoriz = async () => {
      try {
        const response = await getData(API_ROUTES.USERS.GET.AUTHORIZATION);
        setAuth(response.data);
      } catch (error) {
        console.log('error on fetch auth ', error);
      }
    };
    fetchAuthoriz();
  }, []);

  // data fetch with user id
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        try {
          if (localStorage.getItem('token')) setLog(true);
        } catch (error) {
          console.log(error);
        }
        const response = await getDashboard(API_ROUTES.USERS.GET.DASHBOARD);
        setData(response.data);

        if (response.data.locale) {
          i18n.changeLanguage(response.data.locale.slice(0, 2));
        }

        localStorage.setItem(
          'ids',
          JSON.stringify({
            user: response.data.user,
            id_agency: response.data.id_agency,
            agency: response.data.agency,
            country: response.data.country,
            locale: response.data.locale,
          }),
        );
      } catch (error) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login'); // redirect to login page if error
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {isLog && (
        <div className="dashboard">
          <h1 className="mb-5">{t('pages.dashboard.title')}</h1>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <h2>
                {t('pages.dashboard.content')} {data.firstname}
              </h2>
              <div className="dashboard__cardsContainer">
                <DashboardCardNumber
                  icon={<FaSailboat />}
                  number={data.totalBoats}
                  title={t('pages.dashboard.boatsNumber')}
                  colorIcon="#FFF"
                  backgroundIcon="#845ADF"
                />
                <DashboardCardNumber
                  icon={<FaSailboat />}
                  number={data.sailsBoats}
                  title={t('pages.dashboard.sailsBoatsNumber')}
                  colorIcon="#FFF"
                  backgroundIcon="#23B7E5"
                />
                <DashboardCardNumber
                  icon={<TbSpeedboat />}
                  number={data.motorBoats}
                  title={t('pages.dashboard.enginesBoatsNumber')}
                  colorIcon="#FFF"
                  backgroundIcon="#26BF94"
                />
              </div>
            </>
          )}
          <span className="mt-5">List of visible dashboard:</span>
          <ul>
            {auth.includes(featureDashboardBroker) && (
              <li>Your own dashboard</li>
            )}
            {auth.includes(featureDashboardAgency) && (
              <li>Agency's dashboard</li>
            )}
            {auth.includes(featureDashboardNetwork) && (
              <li>Network's dashboard</li>
            )}
            {auth.includes(featureDashboardAgencyOther) && (
              <li>Dashboard from an other commercial in the Agency</li>
            )}
            {auth.includes(featureDashboardNetworkOther) && (
              <li>Dashboard from an other commercial in the Network</li>
            )}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
}
// Export to call it up in app.jsx
export default Dashboard;
