// import libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Spinner, Table, Toast, ToastContainer } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { MdEditDocument } from 'react-icons/md';
import { RiAiGenerate } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
// import components
import SearchBar from '../../components/searchBar';
import ButtonModalDocuments from '../../components/buttonModalDocuments';
import ButtonModal from '../../components/buttonModal';
// import utils
import {
  deleteDataID,
  getData,
  getDataID,
  getDataID2File,
} from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import css
import '../../assets/styles/documents.scss';

/*
Documents page
*/

export default function Documents() {
  const { id: boatId } = useParams();
  const [searchedName, setSearchedName] = useState('');
  const [searchedDesc, setSearchedDesc] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isGenerating, setGenerate] = useState(false);
  const [data, setData] = useState([]);
  const [boat, setBoat] = useState();
  const [updateData, setUpdateData] = useState(false);
  const [show, setShow] = useState(false);
  // features
  const featureAdminTemplatesAdd = 'admin_templates_add';
  const featureAdminTemplatesUpdate = 'admin_templates_update';
  const featureAdminTemplatesDelete = 'admin_templates_delete';
  const [auth, setAuth] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAuthoriz = async () => {
      setLoading(true);
      try {
        const response = await getData(API_ROUTES.USERS.GET.AUTHORIZATION);
        setAuth(response.data);
        const responseData = await getData(API_ROUTES.DOCUMENTS.GET.TEMPLATES);
        setData(responseData.data);
        const responseBoat = await getDataID(
          API_ROUTES.BOATS.GET.DETAILS,
          boatId,
        );
        setBoat(responseBoat.data.detail);
      } catch (error) {
        console.log('error on fetch auth ', error);
      }
      setLoading(false);
    };
    fetchAuthoriz();
  }, [updateData, boatId]);

  const handleTemplate = async (template) => {
    setGenerate(true);
    try {
      const response = await getDataID2File(
        API_ROUTES.DOCUMENTS.GET.TEMPLATE_GENERATE,
        boatId,
        template.id,
      );

      // Vérifier que la réponse contient des données
      if (!response || !response.data) {
        console.error("Erreur: le fichier n'a pas été correctement généré.");
        return;
      }

      // Créer un lien de téléchargement pour le fichier reçu
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      // Vérifier que le blob n'est pas vide
      if (blob.size === 0) {
        console.error('Erreur: Le fichier généré est vide.');
        return;
      }

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${boat.brand.replace(' ', '_')}_${boat.model.replace(
        ' ',
        '_',
      )}_${template.name.replace(' ', '_')}.docx`; // Nom du fichier
      link.click();
    } catch (error) {
      console.log('Erreur lors de la création des fichiers L90:', error);
      if (error.response.status === 403) {
        setShow(true);
      }
    }
    setGenerate(false);
  };

  const handleDelete = async (fileId) => {
    try {
      const response = await deleteDataID(
        API_ROUTES.DOCUMENTS.DELETE.TEMPLATE,
        fileId,
      );
      if (response.status === 200) {
        setUpdateData(!updateData);
      }
    } catch (error) {
      console.log('error documents index delete L63: ', error);
    }
  };

  return (
    <div className="documents">
      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        ></Spinner>
      ) : (
        <React.Fragment>
          {boat && (
            <h1>
              ID: {boatId} Brand: {boat.brand} Model: {boat.model}
            </h1>
          )}
          <div className="documents__bars">
            <SearchBar
              placeholder={t('pages.documents.searchName')}
              value={searchedName}
              setValue={setSearchedName}
            />
            <SearchBar
              placeholder={t('pages.documents.searchDescription')}
              value={searchedDesc}
              setValue={setSearchedDesc}
            />
            {auth.includes(featureAdminTemplatesAdd) ? (
              <ButtonModalDocuments
                nameButton={t('pages.documents.addNew')}
                updateData={updateData}
                setUpdateData={setUpdateData}
              />
            ) : (
              <span></span>
            )}
          </div>
          <Table striped hover className="documents__table">
            <thead>
              <tr>
                <th>{t('pages.documents.name')}</th>
                <th>{t('pages.documents.description')}</th>
                <th>{t('pages.documents.actions')}</th>
              </tr>
            </thead>
            {data && (
              <tbody>
                {data
                  .filter((item) => {
                    return searchedName.toLocaleLowerCase() === ''
                      ? item
                      : item.name.toLocaleLowerCase().includes(searchedName);
                  })
                  .filter((item) => {
                    return searchedDesc.toLocaleLowerCase() === ''
                      ? item
                      : item.description
                          .toLocaleLowerCase()
                          .includes(searchedDesc);
                  })
                  .map((item, index) => {
                    const date = new Date(item.created_at);
                    return (
                      <tr key={index}>
                        <td className="documents__table__cell documents__table__cell__name">
                          <div className="documents__table__cell__nameContainer">
                            <span className="documents__table__cell__nameContainer__documentName">
                              {item.name}
                            </span>
                            <span className="documents__table__cell__nameContainer__date">
                              {date.toLocaleDateString()}
                            </span>
                          </div>
                        </td>
                        <td className="documents__table__cell">
                          {item.description}
                        </td>
                        <td className="documents__table__cell documents__table__cell__actions">
                          <Button
                            onClick={() => handleTemplate(item)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={t('label.generate')}
                            disabled={isGenerating}
                          >
                            {isGenerating ? (
                              <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                size="sm"
                              ></Spinner>
                            ) : (
                              <RiAiGenerate />
                            )}
                          </Button>
                          {auth.includes(featureAdminTemplatesUpdate) && (
                            <ButtonModalDocuments
                              iconButton={<MdEditDocument />}
                              data={item}
                              variantButton="info"
                              updateData={updateData}
                              setUpdateData={setUpdateData}
                              tooltip={t('label.edit')}
                            />
                          )}
                          {auth.includes(featureAdminTemplatesDelete) && (
                            <ButtonModal
                              iconButton={<FaTrashAlt />}
                              variantButton="danger"
                              modalTitle={`${t(
                                'pages.documents.deleteTitle',
                              )} ${item.name}`}
                              modalBody={t('pages.documents.deleteBody')}
                              nameConfirm={t('pages.documents.validate')}
                              variantConfirm="success"
                              handleConfirm={() => handleDelete(item.id)}
                              nameCancel={t('pages.documents.cancel')}
                              variantCancel="danger"
                              tooltip={t('label.delete')}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </Table>
          <hr />
        </React.Fragment>
      )}
      <ToastContainer position="bottom-end" className="mb-3 me-3">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          bg={'danger'}
          autohide
        >
          <Toast.Body>
            <span className="me-auto">403 - Forbidden</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
